import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  output,
  Output,
  signal,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  share,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { IStockSubscription } from '@c/bull-call-spread-content/bull-call-spread.model';
import { DEFAULT_CURRENT_ELEMENTS_VISIBILITY_CONFIG } from '@c/shared/symbol-details-panel/symbol-details-panel.data';
import {
  IEarningDetails,
  IElementsVisibilityConfig,
  ILiveData,
  IPanelSymbolDetails,
  ISymbolIndexDetails,
} from '@c/shared/symbol-details-panel/symbol-details-panel.model';
import { formatMarketCap, getPromptConfiguration } from '@c/shared/symbol-details-panel/symbol-details-panel.utils';
import { TradingHubMode } from '@c/trading-hub/trading-hub.model';
import {
  ChartIntervals,
  DataChannelCommands,
  ExchangeCountriesCodes,
  Features,
  MomentDateTimeFormats,
  RockyPromts,
  TabNames,
  TradingHubTabs,
  UserSettings,
  USExchangesForGoogleSearch,
  WatchlistType,
} from '@const';
import { RelativelyToMarketTimeOptions } from '@mod/admin';
import { IDataChannelCommand } from '@mod/data/data-channel.model';
import { MarketTimeState } from '@mod/data/market-time.model';
import { Flags, ISymbolSmiley, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { DialogsService } from '@s/common';
import { DataChannelService } from '@s/data-channel.service';
import { DividendsItemResponseModel, DividendsService } from '@s/dividends.service';
import { EarningsService } from '@s/earnings.service';
import { EditionsService } from '@s/editions.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { MarketTimeService } from '@s/market-time.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { SmileyDataService } from '@s/smiley-data.service';
import { StreamingService } from '@s/streaming.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { WatchlistService } from '@s/watchlist.service';
import { ISymbolDetailsV2 } from '@t/wheel/wheel.types';
import { convertToEasternTime, isNullOrUndefinedOrEmpty } from '@u/utils';

@Component({
  selector: 'app-symbol-details-panel',
  templateUrl: './symbol-details-panel.component.html',
  styleUrls: ['./symbol-details-panel.component.scss'],
  providers: [DecimalPipe],
})
export class SymbolDetailsPanelComponent implements OnInit, OnDestroy {
  @Input() public isDataChannelLiveData: boolean | null;
  @Input() public symbol$: Observable<number | null>;
  @Input() public watchlistType: WatchlistType;
  @Input() public smileyListType: SmileyListType;
  @Input() public securityDetailsMap: Record<number, ISymbolDetailsV2> = {};
  @Input() public set elementsVisibilityConfig(value: Partial<IElementsVisibilityConfig>) {
    if (value) {
      this.currentElementsVisibilityConfig = {
        ...DEFAULT_CURRENT_ELEMENTS_VISIBILITY_CONFIG,
        ...value,
      };

      this.currentElementsVisibilityConfig$.next(this.currentElementsVisibilityConfig);
    }
  }
  @Input() warningMessage: string;

  @Output() lastTradedPriceUpdated = new EventEmitter<{ security_id: number; price: number | null }>();
  @Output() smileyUpdated = new EventEmitter<ISymbolSmiley[]>();

  protected symbolSelected = output<ISymbol>();

  protected readonly maxLongDescriptionLength = 500;
  protected readonly features = Features;
  protected currentElementsVisibilityConfig: IElementsVisibilityConfig = DEFAULT_CURRENT_ELEMENTS_VISIBILITY_CONFIG;
  protected currentElementsVisibilityConfig$ = new BehaviorSubject<IElementsVisibilityConfig>(
    DEFAULT_CURRENT_ELEMENTS_VISIBILITY_CONFIG,
  );

  protected relativelyToMarketTimeLabels = {
    [MarketTimeState.Weekend]: {
      label: 'MARKET CLOSED',
      icon: null,
      cssClass: 'market-closed',
      showTradesState: false,
    },
    [MarketTimeState.Holiday]: { label: 'HOLIDAY', icon: null, cssClass: 'holiday', showTradesState: false },
    [MarketTimeState.PreMarket]: { label: 'PRE MARKET', icon: 'pre-market', cssClass: '', showTradesState: true },
    [MarketTimeState.MarketOpened]: {
      label: 'MARKET OPEN',
      icon: null,
      cssClass: 'market-opened',
      showTradesState: false,
    },
    [MarketTimeState.PostMarket]: { label: 'POST MARKET', icon: 'post-market', cssClass: '', showTradesState: true },
    [MarketTimeState.MarketClosed]: {
      label: 'MARKET CLOSED',
      icon: null,
      cssClass: 'market-closed',
      showTradesState: false,
    },
  };

  protected relativelyToMarketTimeEarningsLabels = {
    [RelativelyToMarketTimeOptions.BeforeMarket]: 'Before',
    [RelativelyToMarketTimeOptions.DuringMarket]: 'During',
    [RelativelyToMarketTimeOptions.AfterMarket]: 'After',
  };

  protected subscribedStock: IStockSubscription = null;
  protected securityDetails$: Observable<IPanelSymbolDetails | null> = of(null);
  protected onChangeIsInWatchlist$ = new Subject<void>();

  protected earningReportDate: string | null = null;
  protected earningReportDateShort: string | null = null;

  protected currentSecurityDetails: IPanelSymbolDetails | null = null;
  protected lastTradePrice = 0;
  protected lastTradePriceFormatted = this.formatLastTradedPrice(this.lastTradePrice);

  protected relativelyToMarketTime: MarketTimeState | null = null;
  protected earningRelativelyToMarketTime: RelativelyToMarketTimeOptions | null = null;
  protected isThereAnyTrades = signal<boolean>(null);
  protected lastReceivedLiveData: ILiveData | null = null;

  protected isInWatchlist = false;
  protected defaultFlag: ISymbolSmiley = { id: null, flag: Flags.None, security_id: null };
  protected currentFlag = this.defaultFlag;
  protected smileyListMap: Record<number, ISymbolSmiley> = {};

  protected nextEarningDate: string | null = null;
  protected totalEarningDay: number | null = null;
  protected isEarningsConfirmed = false;

  // prev and next dividends details
  protected divPerShare: number | null = null;
  protected upcomingDividendDate: string | null = null;
  protected upcomingDividendDateShort: string | null = null;
  protected upcomingDividendInDays: number | null = null;

  protected exchangeForGoogleSearch = USExchangesForGoogleSearch;
  protected updateIsThereAnyTradesStateDelay = 3000;

  protected showRockyDataWindow = this.observableService.showRockyDataWindow.value;
  protected showHeatmapDetailsDataWindow = this.observableService.showHeatmapDetailsDataWindow.value;
  protected showRockyIconAlways = this.observableService.showRockyAlways;
  protected isHeatmapFeatureAvailable = this.editionsService.isFeatureAvailable(Features.Heatmap);

  private subscriber = new Subscription();
  private timeoutSub: Subscription;
  private dataChannelSubscribeCommand: IDataChannelCommand | null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dataChannelService: DataChannelService,
    private securityDataDetailsService: SecurityDataDetailsService,
    private symbolsService: SymbolsService,
    private watchlistService: WatchlistService,
    private observableService: ObservableService,
    private dialogsService: DialogsService,
    private userDataService: UserDataService,
    private streamingService: StreamingService,
    private historicalDataService: HistoricalDataService,
    private navigationService: NavigationService,
    private earningsService: EarningsService,
    private smileyDataService: SmileyDataService,
    private marketTimeService: MarketTimeService,
    private dividendsService: DividendsService,
    private decimalPipe: DecimalPipe,
    private editionsService: EditionsService,
  ) {}

  ngOnInit(): void {
    this.securityDetails$ = this.symbol$.pipe(
      distinctUntilChanged(),
      switchMap((securityId) => {
        if (!this.securityDetailsMap || !this.securityDetailsMap[securityId]) {
          return combineLatest([
            from(this.symbolsService.getById(securityId)),
            from(this.securityDataDetailsService.get(securityId)),
            from(this.historicalDataService.get(securityId)),
          ]).pipe(
            filter(([symbol, security, historicalData]) => symbol && security && historicalData),
            map(([symbol, security, historicalData]) => {
              const lastTradePrice = historicalData.length ? historicalData[historicalData.length - 1].close : null;

              return {
                company: symbol.description,
                createdDate: symbol.last_updated.toString(),
                exchange: symbol.exchange_name,
                exchangeCode: symbol.exchange_code,
                countryCode: symbol.country_code,
                industry: security.industry_name,
                lastTradePrice,
                longDescription: security.long_description,
                longDescriptionFormatted: this.formatLongDescription(security.long_description),
                sector: security.sector_name,
                securityId: security.security_id,
                symbol: security.symbol,
                marketCap: security.market_cap ?? null,
                formattedMarketCap: formatMarketCap(security.market_cap),
                priceEarningsRatio: security.pe_ratio ?? null,
                dividendYield: security.dividend_yield ?? null,
                eps: security.earnings_share ?? null,
                percentInsiders: security.percent_insiders ?? null,
                percentInstitutions: security.percent_institutions ?? null,
                isFromCache: false,
                symbolIndexDetails: this.getSymbolIndexDetails(symbol),
              };
            }),
          );
        }

        const symbolDetails = this.securityDetailsMap[securityId];

        const details: IPanelSymbolDetails = {
          company: symbolDetails.company,
          createdDate: symbolDetails.created_date,
          exchange: symbolDetails.exchange,
          exchangeCode: symbolDetails.exchange_code,
          countryCode: symbolDetails.country_code,
          industry: symbolDetails.industry,
          lastTradePrice: symbolDetails.last_traded_price,
          longDescription: symbolDetails.long_description,
          longDescriptionFormatted: this.formatLongDescription(symbolDetails.long_description),
          sector: symbolDetails.sector,
          securityId: symbolDetails.security_id,
          symbol: symbolDetails.symbol,
          marketCap: symbolDetails.market_cap ?? null,
          formattedMarketCap: formatMarketCap(symbolDetails.market_cap),
          priceEarningsRatio: symbolDetails.pe_ratio ?? null,
          dividendYield: symbolDetails.dividend_yield ?? null,
          eps: symbolDetails.earnings_share ?? null,
          percentInsiders: symbolDetails.percent_insiders ?? null,
          percentInstitutions: symbolDetails.percent_institutions ?? null,
          isFromCache: true,
          symbolIndexDetails: this.getSymbolIndexDetails(null),
        };

        return combineLatest([of(details), from(this.symbolsService.getById(securityId))]).pipe(
          map(([symbolDetails, symbol]) => ({
            ...symbolDetails,
            symbolIndexDetails: this.getSymbolIndexDetails(symbol),
          })),
        );
      }),
      share(),
    );

    this.subscriber.add(
      this.securityDetails$
        .pipe(
          tap((securityDetails) => {
            this.currentSecurityDetails = securityDetails;
            this.lastTradedPriceUpdated.emit({
              security_id: securityDetails.securityId,
              price: securityDetails.lastTradePrice,
            });
          }),
        )
        .subscribe(),
    );

    this.subscriber.add(
      combineLatest([this.symbol$.pipe(debounceTime(1000)), this.currentElementsVisibilityConfig$])
        .pipe(
          switchMap(([securityId, config]) => {
            if (!config.showDivPerShare && !config.showUpcomingDividends) {
              return of(null);
            }

            return from(this.dividendsService.get(securityId));
          }),
        )
        .subscribe((dividends) => {
          let prevDividend = null;
          let nextDividend = null;

          if (dividends && dividends.length > 0) {
            const currentDate = convertToEasternTime(moment());
            // min -> max
            const sortedDividends = dividends.sort((a, b) => {
              return convertToEasternTime(a.date).valueOf() - convertToEasternTime(b.date).valueOf();
            });

            prevDividend = [...sortedDividends]
              .reverse()
              .find((item) => convertToEasternTime(item.date).isSameOrBefore(currentDate, 'day'));

            nextDividend = sortedDividends.find((item) => convertToEasternTime(item.date).isAfter(currentDate, 'day'));
          }

          this.updatePrevDividendDetails(prevDividend);
          this.updateNextDividendDetails(nextDividend);
        }),
    );

    this.subscriber.add(
      this.securityDetails$
        .pipe(
          debounceTime(1000),
          filter(
            (securityDetails) =>
              // if is not from cache - historicalData already requested
              securityDetails.isFromCache && this.lastReceivedLiveData?.symbol !== securityDetails.symbol,
          ),
          switchMap((securityDetails) => {
            return combineLatest([
              of(securityDetails),
              from(this.historicalDataService.get(securityDetails.securityId)).pipe(
                catchError((error) => {
                  console.error(
                    `WHEEL :: Something went wrong on fetching historical-data for security_id: ${securityDetails.securityId}, error: ${error}`,
                  );
                  return of(null);
                }),
              ),
            ]);
          }),
          withLatestFrom(this.securityDetails$),
          filter(([[securityDetails, historicalData], currentSecurityDetails]) => {
            // in case of error or if close-price is already received from live-data for current symbol
            // or historicalData is received for old securityDetails
            return (
              historicalData &&
              currentSecurityDetails.securityId === securityDetails.securityId &&
              this.lastReceivedLiveData?.symbol !== securityDetails.symbol
            );
          }),
          tap(([[securityDetails, historicalData]]) => {
            if (!historicalData) {
              this.updateLastTradedPrice(null, securityDetails.securityId);

              return;
            }

            this.updateLastTradedPrice(
              historicalData?.length ? historicalData[historicalData.length - 1].close : null,
              securityDetails.securityId,
            );
          }),
        )
        .subscribe(),
    );

    this.subscriber.add(
      this.smileyDataService.updateSymbolsSmileySignal$
        .pipe(
          switchMap(() => this.smileyDataService.get(this.smileyListType)),
          withLatestFrom(this.securityDetails$),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          filter(([updatedSmiley, securityDetails]) => Boolean(securityDetails)),
        )
        .subscribe(([updatedSmiley, securityDetails]) => {
          const normalizedSmileyList = updatedSmiley.reduce((acc, item) => {
            acc[item.security_id] = item;
            return acc;
          }, {});

          this.smileyListMap = normalizedSmileyList;
          this.currentFlag = normalizedSmileyList[securityDetails.securityId] ?? {
            ...this.defaultFlag,
            security_id: securityDetails.securityId,
          };

          // keep record for current symbol even if flag is equal to none
          this.smileyListMap[securityDetails.securityId] = { ...this.currentFlag };
        }),
    );

    this.subscriber.add(
      this.symbol$
        .pipe(
          distinctUntilChanged(),
          switchMap((securityId) => {
            return combineLatest([of(securityId), this.watchlistService.get(this.watchlistType)]);
          }),
          tap(([securityId, watchlist]) => {
            const watchlistItem = watchlist.find((item) => item.security_id === securityId);
            this.isInWatchlist = Boolean(watchlistItem);
          }),
        )
        .subscribe(),
    );

    this.subscriber.add(
      this.onChangeIsInWatchlist$
        .pipe(
          withLatestFrom(this.securityDetails$),
          tap(() => {
            // change state of button-icon immediately
            this.isInWatchlist = !this.isInWatchlist;
            this.changeDetectorRef.detectChanges();
          }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          switchMap(([signal, securityDetails]) => {
            return combineLatest([this.watchlistService.get(this.watchlistType), of(securityDetails)]);
          }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          filter(([watchlist, currentSymbol]) => Boolean(currentSymbol)),
          map(([watchlist, currentSymbol]) => {
            const watchlistItem = watchlist.find((item) => item.security_id === currentSymbol?.securityId);
            return { watchlistItem, currentSymbol };
          }),
          // do nothing if there is nothing to change
          filter(({ watchlistItem }) => Boolean(watchlistItem) !== this.isInWatchlist),
          tap(({ watchlistItem }) => {
            this.isInWatchlist = !watchlistItem;
            this.changeDetectorRef.detectChanges();
          }),
          switchMap(({ watchlistItem, currentSymbol }) => {
            return watchlistItem
              ? this.watchlistService.remove(watchlistItem.id, this.watchlistType)
              : this.watchlistService.insert(currentSymbol.securityId, this.watchlistType);
          }),
        )
        .subscribe(),
    );

    this.subscriber.add(
      this.watchlistService.updateWatchlistSignal$
        .pipe(
          switchMap(() => {
            return combineLatest([this.symbol$, this.watchlistService.get(this.watchlistType)]);
          }),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          filter(([securityId, watchlist]) => Boolean(securityId)),
          tap(([securityId, watchlist]) => {
            const watchlistItem = watchlist.find((item) => item.security_id === securityId);
            this.isInWatchlist = Boolean(watchlistItem);
            this.changeDetectorRef.detectChanges();
          }),
        )
        .subscribe(),
    );

    this.subscriber.add(
      this.securityDetails$
        .pipe(
          distinctUntilChanged((v1, v2) => v1?.securityId === v2?.securityId),
          tap((securityDetails) => this.updateSymbolData(securityDetails)),
          debounceTime(this.updateIsThereAnyTradesStateDelay),
          tap(() => {
            // if there is no result from live-data in updateIsThereAnyTradesStateDelay (3s) then set it to false
            if (this.isThereAnyTrades() === null) {
              this.isThereAnyTrades.set(false);
            }
          }),
        )
        .subscribe(),
    );

    this.relativelyToMarketTime = this.getRelativelyToMarketTime();
    this.subscriber.add(
      this.marketTimeService.marketStateChanged$.subscribe(() => {
        this.relativelyToMarketTime = this.getRelativelyToMarketTime();
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
    }

    if (this.dataChannelSubscribeCommand) {
      this.dataChannelService.unsubscribe(this.dataChannelSubscribeCommand);
      this.dataChannelSubscribeCommand = null;
    }

    if (this.subscribedStock) {
      this.streamingService.unsubscribe(this.subscribedStock.subscriptionId, this.subscribedStock.symbol);
    }

    this.subscriber.unsubscribe();
  }

  protected onSymbolSelected(symbol: ISymbol): void {
    this.symbolSelected.emit(symbol);
  }

  private updateLastTradedPrice(value: number | null, securityId: number): void {
    if (isNullOrUndefinedOrEmpty(value)) {
      this.lastTradePrice = null;
      this.lastTradePriceFormatted = this.formatLastTradedPrice(null);

      return;
    }

    this.lastTradePrice = value;
    this.lastTradePriceFormatted = this.formatLastTradedPrice(value);
    this.lastTradedPriceUpdated.emit({ security_id: securityId, price: value });
  }

  private updateSymbolData(securityDetails: IPanelSymbolDetails): void {
    if (!securityDetails) {
      return;
    }

    this.updateLastTradedPrice(securityDetails.lastTradePrice, securityDetails.securityId);

    this.isThereAnyTrades.set(null);
    this.updateNextEarningDate(null);

    if (this.subscribedStock) {
      this.streamingService.unsubscribe(this.subscribedStock.subscriptionId, this.subscribedStock.symbol);
    }

    if (this.isDataChannelLiveData) {
      if (this.dataChannelSubscribeCommand) {
        this.dataChannelService.unsubscribe(this.dataChannelSubscribeCommand);
        this.dataChannelSubscribeCommand = null;
      }

      this.dataChannelSubscribeCommand = {
        subscriptionId: uuidV4(),
        name: DataChannelCommands.UsLiveData,
        data: {
          symbol: securityDetails.symbol,
          interval: ChartIntervals.Minute5,
          isStartWithMarketTimeData: true,
        },
        handler: this.handleLiveDataCallback.bind(this),
      };

      this.dataChannelService.subscribe(this.dataChannelSubscribeCommand);
    } else {
      this.subscribedStock = {
        subscriptionId: uuidV4(),
        symbol: {
          symbol: securityDetails.symbol,
          country_code: ExchangeCountriesCodes.US,
        },
      };

      this.streamingService.subscribe(
        this.subscribedStock.subscriptionId,
        this.subscribedStock.symbol,
        {},
        () => null,
        this.handleLiveDataCallback.bind(this), // avoid high/low/open checking
      );
    }

    // important: do not remove, temp testing on DEV - turn-off update request on each change
    // root component should request smiley-data, if not - add "input" settings and update it optionally (just one time - on init)
    // this.smileyDataService.get(this.smileyListType)
    //   .subscribe((smileyList) => {
    //     const normalizedSmileyList = smileyList.reduce((acc, item) => {
    //       acc[item.security_id] = item;
    //       return acc;
    //     }, {});
    //
    //     this.smileyListMap = normalizedSmileyList;
    //     this.currentFlag = normalizedSmileyList[securityDetails.securityId] ?? this.defaultFlag;
    //   });

    if (this.currentElementsVisibilityConfig.showUpcomingEarnings) {
      from(this.earningsService.getNext(securityDetails.securityId))
        .pipe(
          take(1),
          catchError((error) => {
            console.error(`WHEEL :: Something went wrong on fetching wheel earning, error: ${error}`);
            return of(null);
          }),
          tap((earning) => {
            // there is an issue with type returned from "earningsService.getNext()"
            this.updateNextEarningDate(earning as unknown as IEarningDetails);
          }),
        )
        .subscribe();
    } else {
      this.updateNextEarningDate(null);
    }
  }

  private handleLiveDataCallback(data: ILiveData): void {
    if (data.symbol !== this.currentSecurityDetails?.symbol) {
      return;
    }

    this.isThereAnyTrades.set(data.volume !== null && data.volume !== undefined && Boolean(data.close));

    if (data && this.isThereAnyTrades() && !data.isPreMarket && !data.isPostMarket) {
      this.updateLastTradedPrice(data.close, this.currentSecurityDetails.securityId);
      this.lastReceivedLiveData = data;
    }

    this.changeDetectorRef.markForCheck();
  }

  private updatePrevDividendDetails(dividend: DividendsItemResponseModel | null): void {
    if (!dividend) {
      this.divPerShare = null;

      return;
    }

    this.divPerShare = dividend.value;
  }

  private updateNextDividendDetails(dividend: DividendsItemResponseModel | null): void {
    if (!dividend) {
      this.upcomingDividendDate = null;
      this.upcomingDividendDateShort = null;
      this.upcomingDividendInDays = null;

      return;
    }

    const currentEtDate = convertToEasternTime(moment());
    const exDate = convertToEasternTime(dividend.date);

    this.upcomingDividendInDays = exDate.clone().diff(currentEtDate, 'day', false) + 1;
    this.upcomingDividendDate = exDate.clone().format(MomentDateTimeFormats.ReadableDateFullYear);
    this.upcomingDividendDateShort = exDate.clone().format(MomentDateTimeFormats.ReadableNoYearDate);
  }

  private updateNextEarningDate(earning: IEarningDetails | null): void {
    if (!earning) {
      this.totalEarningDay = null;
      this.nextEarningDate = null;
      this.earningReportDate = null;
      this.earningReportDateShort = null;
      this.isEarningsConfirmed = false;
      this.earningRelativelyToMarketTime = null;

      return;
    }

    const days = earning.earnings_in_days > 1 ? 'days' : 'day';
    const earningReportDate = convertToEasternTime(earning.report_date).format(
      MomentDateTimeFormats.ReadableDateFullYear,
    );
    const earningReportDateShort = convertToEasternTime(earning.report_date).format(
      MomentDateTimeFormats.ReadableNoYearDate,
    );

    this.totalEarningDay = earning.earnings_in_days;
    this.isEarningsConfirmed = Boolean(earning.date_confirmed);
    this.earningRelativelyToMarketTime = earning.before_after_market;
    this.earningReportDate = earningReportDate;
    this.earningReportDateShort = earningReportDateShort;
    this.nextEarningDate = `Earnings in ${this.totalEarningDay} ${days} on ${earningReportDate}`;
  }

  public onChangeSymbolFlag(newFlag: Flags): void {
    // if it wasn't changed - do nothing
    if (this.currentFlag.flag === newFlag) {
      return;
    }

    // keep prev flag state for further checking
    const currentFlag = { ...this.currentFlag };
    this.currentFlag = { ...currentFlag, flag: newFlag };
    this.updateSmileyForOtherComponents(this.currentFlag);
  }

  private updateSmileyForOtherComponents(newSmiley: ISymbolSmiley): void {
    const currentSymbolSmileys = Object.values(this.smileyListMap);
    const newSymbolSmileys = currentSymbolSmileys.map((smiley) => {
      return smiley.security_id === newSmiley.security_id ? newSmiley : smiley;
    });

    if (!newSymbolSmileys.find((item) => item.security_id === newSmiley.security_id)) {
      newSymbolSmileys.push(newSmiley);
    }

    this.smileyUpdated.emit(newSymbolSmileys);
  }

  public onChangeWatchlist(): void {
    this.onChangeIsInWatchlist$.next();
  }

  public redirectToAssetCorrelationPage(securityDetails: IPanelSymbolDetails): void {
    const redirectSymbolPayload: ISymbol = {
      description: securityDetails.company,
      exchange_code: securityDetails.exchangeCode,
      exchange_id: 0, // to check it
      exchange_name: securityDetails.exchange,
      // @ts-expect-error: moment and string
      last_updated: securityDetails.createdDate, // to check it, wasn't necessary yet
      security_id: securityDetails.securityId,
      symbol: securityDetails.symbol,
      type: 'stock', // it will be enum in future
      country_code: securityDetails.countryCode,
    };

    from(this.navigationService.redirectToTab(TabNames.AssetCorrelation, { symbol: redirectSymbolPayload }))
      .pipe(take(1))
      .subscribe();
  }

  public openNews(securityDetails: IPanelSymbolDetails): void {
    window.open(
      `https://www.google.com/finance/quote/${securityDetails?.symbol}:${this.exchangeForGoogleSearch[securityDetails?.exchangeCode] || 'NYSE'}`,
      '_blank',
    );
  }

  public setEarningsAnalysisSymbol(securityId: number): void {
    this.navigationService.redirectToTab(TabNames.EarningsAnalysis, {}, securityId);
  }

  public openTradingHubModal(data: IPanelSymbolDetails): void {
    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.IBot3);
    this.observableService.tradingHubTab.next(TradingHubTabs.IBot3);
    const promptConfiguration = getPromptConfiguration(
      data,
      this.currentElementsVisibilityConfig,
      this.divPerShare,
      this.earningReportDateShort,
      this.upcomingDividendDateShort,
    );

    let prompt = RockyPromts.DataWindow.valueOf();
    prompt +=
      promptConfiguration
        .filter((configuration) => configuration.visible)
        .map((configuration, index) => {
          return `${index + 1}. <b>'${configuration.name}'</b>${configuration.value !== null ? ' and its value ' + configuration.value : ''}`;
        })
        .join(';<br>') + ';';

    this.dialogsService.openTradingHubModal(TradingHubMode.Help, prompt);
  }

  protected redirectToExpectedMoveDemoPage(): void {
    this.editionsService.redirectToDemoPage(Features.ExpectedMove);
  }

  private getSymbolIndexDetails(symbol: ISymbol): ISymbolIndexDetails {
    if (!symbol) {
      return { isVisible: false, displayValue: '' };
    }

    return {
      isVisible:
        symbol.country_code === ExchangeCountriesCodes.US &&
        (!!symbol.is_dow_jones || !!symbol.is_nasdaq || !!symbol.is_sp_500 || !!symbol.is_sp_100),
      displayValue: [
        !!symbol.is_sp_100 && 'S&P 100',
        !!symbol.is_sp_500 && 'S&P 500',
        !!symbol.is_nasdaq && 'NASDAQ 100',
        !!symbol.is_dow_jones && 'DOW 30',
      ]
        .filter(Boolean)
        .join(', '),
    };
  }

  private formatLastTradedPrice(price: number): {
    value: number;
    formattedValue: string;
    visiblePart: string;
    hiddenFraction: string;
  } {
    if (!price) {
      return {
        value: 0,
        formattedValue: '0',
        visiblePart: '0',
        hiddenFraction: '',
      };
    }

    const fractionLength = 4;
    const priceString = this.decimalPipe.transform(price, '1.2-4');
    const fraction = priceString.split('.')[1] ?? '';
    const hiddenFraction = String('0000').slice(0, fractionLength - fraction.length);

    return {
      value: price,
      formattedValue: priceString,
      visiblePart: priceString,
      hiddenFraction,
    };
  }

  private formatLongDescription(description: string, maxLength: number = this.maxLongDescriptionLength): string {
    if ((description ?? '').length <= maxLength) {
      return description;
    }

    const punctuationMarks = ['.', ',', ':', ';', '!', '?'];
    let cutIndex = description.lastIndexOf(' ', maxLength);

    // If there's no space within the limit, cut at the max length
    if (cutIndex === -1) {
      cutIndex = maxLength;
    }

    let truncatedText = description.slice(0, cutIndex);

    // Check the last character of the truncated text
    const lastChar = truncatedText.slice(-1);

    // If the last character is a punctuation mark, remove it and add "..."
    if (punctuationMarks.includes(lastChar)) {
      truncatedText = truncatedText.slice(0, -1);
    }

    return truncatedText + '...';
  }

  private getRelativelyToMarketTime(): MarketTimeState {
    const isWeekend = this.marketTimeService.isWeekend$.getValue();
    const isHoliday = this.marketTimeService.isHoliday$.getValue();
    const isPreMarket = this.marketTimeService.isPreMarket$.getValue();
    const isMarketOpened = this.marketTimeService.isMarketOpened$.getValue();
    const isPostMarket = this.marketTimeService.isPostMarket$.getValue();

    let marketTimeState = MarketTimeState.MarketOpened;

    if (isHoliday) {
      marketTimeState = MarketTimeState.Holiday;
    } else if (isWeekend) {
      marketTimeState = MarketTimeState.Weekend;
    } else if (isPreMarket) {
      marketTimeState = MarketTimeState.PreMarket;
    } else if (isPostMarket) {
      marketTimeState = MarketTimeState.PostMarket;
    } else if (!isMarketOpened) {
      marketTimeState = MarketTimeState.MarketClosed;
    }

    return marketTimeState;
  }
}
